import { defineStore } from 'juou-managebase-ui';

export interface BilliardsStore {
  currentStoreId?: number;
}

export const useStore = defineStore({
  id: 'juou-billiards-store',
  state: (): BilliardsStore => ({
    currentStoreId: undefined,
  }),
  getters: {

  },
  actions: {
    setCurrentStoreId(_v: number) {
      (this as any).currentStoreId = _v;
    },
  },
});
