<template>
  <div>
    <StoreDetail/>
    <div class="juou-card" style="margin-top: 16px;">

      <JuouProTable
        ref="tableRef"
        title="门店设备"
        :columns="columns"
        :get-data="getData"
        :on-add="addFun"
        :on-edit="updateFun"
        :on-delete="deleteFun"
        :loading="loading"
        :is-dangder-delete="true"
        :format-dangder-delete-info="formatDeleteInfo"
        row-key="id"
        :operates="operates"
        :add-init-values="addInitValue"
        :codition-init-values="conditionsInitValue"
      />
      <JuouDangerDialog
        ref="stopDialog"
        title="警告"
        opt-name="确定"
        :on-ok="handleStopAction"
        :format-dangder-delete-info="stopDangderInfo"
      />
      <JuouProForm
        :items="vipPriceItems"
        :is-dialog="true"
        title="会员价"
        ref="vipPriceFormRef"
        :on-ok="handleUpdateVipPrice"
      />
      <colldevice ref="collDeviceRef" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  useBaseAsync,
  JuouProForm,
  JuouProFormItem,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage, ElMessageBox } from 'element-plus';
import { ref, watchEffect } from 'vue';
import dayjs from 'dayjs';
import StoreDetail from '@/components/StoreDetail.vue';
import { useStore } from '@/store/store';
import { todaySecFormat } from '@/utils';
import colldevice from './colldevice.vue';

const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();
const stopDialog = ref<InstanceType<typeof JuouDangerDialog>>();
const collDeviceRef = ref<InstanceType<typeof colldevice>>();
const store = useStore();


const addInitValue = {

};
const conditionsInitValue = {
};

const typeOption = ref<any[]>([]);

const levelLength = ref(0);

const vipPriceItems = ref<JuouProTableColumn[]>([]);
const vipPriceFormRef = ref<InstanceType<typeof JuouProForm>>();

const currentStoreDevice = ref<any>();

// eslint-disable-next-line max-lines-per-function
const init = useBaseAsync(async() => {
  const [areaTypeRes] = await Promise.all([useApi().areatypeApi.listAreaTypeareatype()]);
  const options
    = areaTypeRes.data.data?.map((item: any) => ({
      value: item.id,
      label: item.name,
    })) || [];
  typeOption.value = [
    ...options,
    {
      value: -1,
      label: '开门',
    },

  ];
  columns.value = [
    {
      name: 'sequence',
      label: '序号',
      type: 'number',
      rule: [{ required: true }],
    },
    {
      name: 'typeId',
      label: '类型',
      type: 'searchSelect',
      clearable: false,
      filterable: false,
      options: typeOption.value,
      rule: [{ required: true }],
    },
    {
      name: 'name',
      label: '名称',
      type: 'string',
      width: 80,
      rule: [{ required: true }],
    },
    {
      name: 'price',
      label: '单价',
      type: 'string',
      width: 80,
      placeholder: '请填写单价，开门类型无需填写',
    },
    {
      name: 'cashPledgePrice',
      label: '押金金额',
      type: 'string',
      width: 80,
      placeholder: '请填写押金金额，开门类型无需填写',
    },

    /* {
      name: 'levelPrice',
      label: '会员价',
      type: 'string',
      width: 80,
      hideCreate: true,
      hideEdit: true,
      render: {
        type: 'link',
        text: '详情',
        onClick: async({ row }: any) => {
          // 门禁
          if (!row.typeId) {
            ElMessage.warning('门禁不能设置会员价');
            return;
          }
          currentStoreDevice.value = row;
          console.log(row);
          const res = await useApi().storeCustomerApi.getScoreLevelMapStoreCustomer(row.storeId);
          console.log(res);
          vipPriceItems.value = res?.data?.data?.map((item: any, inx: number) => ({
            name: String(inx),
            label: `V${inx}`,
            type: 'string',
            placeholder: '请输入对应会员等级的价格',
          })) || [];
          vipPriceFormRef.value?.setFormState(row.levelPrice?.reduce((vals: any, current: any, inx: number) => {
            vals[String(inx)] = current;
            return vals;
          }, {} as any) || {});
          vipPriceFormRef.value?.showDialog();
        },
      },
    }, */
    {
      name: 'deviceIdHex',
      label: '设备',
      type: 'string',
      width: 80,
      rule: [{ required: true }],
    },
    {
      name: 'customPhone',
      label: '使用客户',
      type: 'string',
      width: 80,
      hideCreate: true,
      hideEdit: true,
    },
    {
      name: 'openStatus',
      label: '状态',
      type: 'string',
      hideCreate: true,
      hideEdit: true,
      render: ({ row }: any) => {
        if (!row.typeId) {
          return '-';
        }

        if (row.customPhone && !row.customEndTime) {
          if (row.customBillId) {
            return `押金已开 ${todaySecFormat(dayjs().diff(dayjs(row.customStartTime)) / 1000)}`;
          }
          return `余额已开 ${todaySecFormat(dayjs().diff(dayjs(row.customStartTime)) / 1000)}`;
        }

        if (row.customPhone && row.customEndTime && dayjs().isBefore(dayjs(row.customEndTime))) {
          return (
            `剩余${
              todaySecFormat(dayjs(row.customEndTime).diff() / 1000)}`
          );
        }

        return '空闲';
      },
    },

  ];
});


const operates: any[] = [
  {
    key: 'colldevice',
    name: '联动设备',
    handler: useBaseAsync((row: any) => {
      collDeviceRef.value?.show(row.id);
    }),
  },
  {
    key: 'opendoor',
    name: '远程开门',
    handler: useBaseAsync(async(row: any) => {
      await ElMessageBox({
        title: '确定',
        message: '是否确定要远程打开门禁，请谨慎操作！',
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        showCancelButton: true,
      });
      await useApi().deviceApi.deviceAdminOpendoorDevice(row.deviceId);
      ElMessage.success('开门成功');
    }),
  },
  {
    key: 'stopdevice',
    name: '结束计费',
    handler: (row: any) => {
      stopDialog.value?.showDialog(row);
    },
  },
];

const handleStopAction = useBaseAsync(async(data: any) => {
  await useApi().storeDeviceApi.stopStoreDeviceStoreDevice(data.id);
  ElMessage.success('结束成功');
  tableRef.value?.refresh();
});

const loading = ref(false);


const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  if (!store.currentStoreId) {
    return {
      total: 0,
      data: [],
    };
  }
  try {
    loading.value = true;
    const res = await useApi().storeDeviceApi.listStoreDeviceStoreDevice(store.currentStoreId);
    const tableData: JuouProTableData = {
      total: res.data.data?.length || 0,
      data: res.data.data?.map((item: any) => ({
        ...item,
        hideOp: {
          opendoor: Boolean(item.typeId),
          stopdevice: !item.typeId,
        },
      })) || [],
    };

    return tableData;
  } finally {
    loading.value = false;
  }
});

const formatDeleteInfo = (row: any) => `您正在进行删除(${row.name})操作，请谨慎操作`;

const stopDangderInfo = () => '您正在进行停止设备计费操作，请谨慎操作';

const addFun = useBaseAsync(async(data: any) => {
  if (!store.currentStoreId) {
    ElMessage.error('请先切换选中一个门店');
    return;
  }
  await useApi().storeDeviceApi.addStoreDeviceStoreDevice({
    ...data,
    storeId: store.currentStoreId,
    typeId: data.typeId === -1 ? undefined : data.typeId,
  });
  ElMessage.success('添加成功');
});

const updateFun = useBaseAsync(async(data: any) => {
  if (!store.currentStoreId) {
    ElMessage.error('请先切换选中一个门店');
    return;
  }
  await useApi().storeDeviceApi.updateStoreDeviceStoreDevice({
    ...data,
    price: data.typeId ? data.price : undefined,
    typeId: data.typeId === -1 ? undefined : data.typeId,
  });
  ElMessage.success('修改成功');
});

const deleteFun = useBaseAsync(async(data: any) => {
  if (!store.currentStoreId) {
    ElMessage.error('请先切换选中一个门店');
    return;
  }
  await useApi().storeDeviceApi.delStoreDeviceStoreDevice(data.id);
  ElMessage.success('删除成功');
});

const handleUpdateVipPrice = useBaseAsync(async(data: any) => {
  if (!currentStoreDevice.value) {
    return;
  }
  currentStoreDevice.value.levelPrice = Object.values(data);
  await updateFun(currentStoreDevice.value);
  tableRef.value?.refresh();
});


init();

watchEffect(() => {
  tableRef.value?.refresh();
});

</script>
<style lang="scss" scoped>
</style>
