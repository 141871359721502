<template>
  <div>
    <StoreDetail/>
    <div class="juou-card" style="margin-top: 16px;">

      <JuouProTable
        ref="tableRef"
        title="小时套餐"
        :paginations="{ pageSize: 10 }"
        :columns="columns"
        :get-data="getData"
        :on-add="addFun"
        :on-edit="updateFun"
        :on-delete="deleteFun"
        :loading="loading"
        :is-dangder-delete="true"
        :format-dangder-delete-info="formatDeleteInfo"
        row-key="id"
        :operates="operates"
        :add-init-values="addInitValue"
        :codition-init-values="conditionsInitValue"
      />

      <ticket ref="ticketDialogRef" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  useBaseAsync,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { ref, watchEffect } from 'vue';
import dayjs from 'dayjs';
import StoreDetail from '@/components/StoreDetail.vue';
import { useStore } from '@/store/store';
import { getTodaySec, todaySecToDayjs } from '@/utils';
import ticket from './ticket.vue';

const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();
const store = useStore();
const hiddenOption = [
  {
    value: false,
    label: '显示',
  },
  {
    value: true,
    label: '隐藏',
  },
];

const addInitValue = {

};
const conditionsInitValue = {
};
const ticketDialogRef = ref<InstanceType<typeof ticket>>();

// eslint-disable-next-line max-lines-per-function
const init = useBaseAsync(async() => {
  const [areaTypeRes] = await Promise.all([useApi().areatypeApi.listAreaTypeareatype()]);
  const areaTypeOptions
    = areaTypeRes.data.data?.map((item: any) => ({
      value: item.id,
      label: item.name,
    })) || [];
  columns.value = [
    {
      name: 'sequence',
      label: '序号',
      type: 'number',
      rule: [{ required: true }],
    },
    {
      name: 'name',
      label: '名称',
      type: 'string',
      rule: [{ required: true }],
    },
    {
      name: 'typeId',
      label: '类型',
      type: 'searchSelect',
      rule: [{ required: true }],
      options: areaTypeOptions,
    },
    {
      name: 'time',
      label: '服务时间',
      type: 'timepick',

      rule: [{ required: true }],
      render: ({ row }: any) => dayjs(row.time).format('HH时mm分ss秒'),
    },
    {
      name: 'price',
      label: '售价',
      type: 'string',
      width: 80,
      rule: [{ required: true }],
    },
    {
      name: 'lmt',
      label: '限购',
      type: 'number',
      placeholder: '0为不限',
      width: 80,
      rule: [{ required: true }],
      render: ({ row }: any) => (row.lmt === 0 ? '不限' : String(row.lmt)),
    },

    {
      name: 'saleCount',
      label: '已售/份',
      type: 'number',
      hideCreate: true,
      hideEdit: true,
    },
    {
      name: 'sumCount',
      label: '总数/份',
      type: 'number',
      hideCreate: true,
      hideEdit: true,
    },
    {
      name: 'usedCount',
      label: '已核销/份',
      type: 'number',
      hideCreate: true,
      hideEdit: true,
    },
    {
      name: 'unusedCount',
      label: '未核销/份',
      type: 'number',
      hideCreate: true,
      hideEdit: true,
      render: ({ row }: any) => String(row.sumCount - row.usedCount),
    },
    {
      name: 'hide',
      label: '是否隐藏',
      type: 'searchSelect',
      options: hiddenOption,
      rule: [{ required: true }],
      render: ({ row: { hide } }: any) => {
        const hideInfo = hiddenOption.find((item: any) => item.value === hide);
        if (!hideInfo) {
          return '-';
        }
        return hideInfo.label;
      },
    },

  ];
});


const operates: any[] = [
  {
    key: 'ticket',
    name: '查看小时券',
    handler: (row: any) => {
      ticketDialogRef.value?.show(row.id);
    },
  },
];

const loading = ref(false);


const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  if (!store.currentStoreId) {
    return {
      total: 0,
      data: [],
    };
  }
  try {
    loading.value = true;
    const res = await useApi().hourPackageApi.pageHourPackageHourPackage({
      conditions: Object.entries(filter.conditions).reduce((conditions, [key, val]) => {
        if (val === null || val === undefined || val === '') {
          return conditions;
        }
        conditions[key] = val;
        return conditions;
      }, {
        storeId: store.currentStoreId,
      } as any),
      pageQuery: {
        page: filter.paginations.page - 1,
        pageSize: filter.paginations.pageSize,
      },


    });
    const tableData: JuouProTableData = {
      total: res.data.data?.total || 0,
      data: res.data.data?.data || [],
    };
    tableData.data = tableData.data.map((item) => ({
      ...item,
      time: todaySecToDayjs(item.sec).toDate(),
    }));
    return tableData;
  } finally {
    loading.value = false;
  }
});

const formatDeleteInfo = (row: any) => `您正在进行删除(${row.name})操作，请谨慎操作`;


const addFun = useBaseAsync(async(data: any) => {
  if (!store.currentStoreId) {
    ElMessage.error('请先切换选中一个门店');
    return;
  }
  await useApi().hourPackageApi.addHourPackageHourPackage({
    ...data,
    storeId: store.currentStoreId,
    sec: getTodaySec(dayjs(data.time)),
  });
  ElMessage.success('添加成功');
});

const updateFun = useBaseAsync(async(data: any) => {
  console.log(data.sec);
  await useApi().hourPackageApi.updateHourPackageHourPackage({
    ...data,
    sec: getTodaySec(dayjs(data.time)),
  });
  ElMessage.success('修改成功');
});

const deleteFun = useBaseAsync(async(data: any) => {
  if (!store.currentStoreId) {
    ElMessage.error('请先切换选中一个门店');
    return;
  }
  await useApi().hourPackageApi.delHourPackageHourPackage(data.id);
  ElMessage.success('删除成功');
});


init();

watchEffect(() => {
  tableRef.value?.refresh();
});

</script>
<style lang="scss" scoped>
</style>
