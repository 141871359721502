<template>
  <div class="juou-card" >

    <JuouProTable
      ref="tableRef"
      title="区域类型管理"
      :columns="columns"
      :get-data="getData"
      :on-add="addFun"
      :on-edit="editFun"
      :on-delete="deleteFun"
      :loading="loading"
      :is-dangder-delete="true"
      :format-dangder-delete-info="formatDeleteInfo"
      row-key="id"
      :operates="operates"
      :add-init-values="addInitValue"
      :codition-init-values="conditionsInitValue"
    />
</div>
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  useBaseAsync,
  useBaseUserStore,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { ref, watchEffect } from 'vue';
import dayjs from 'dayjs';
import StoreDetail from '@/components/StoreDetail.vue';
import { useStore } from '@/store/store';

const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();
const store = useStore();


const addInitValue = {

};
const conditionsInitValue = {
};


const init = useBaseAsync(() => {
  columns.value = [
    {
      name: 'name',
      label: '类型名称',
      type: 'string',
      rule: [{ required: true }],
    },
    {
      name: 'bgImg',
      label: '区域背景图',
      type: 'image',
      rule: [{ required: true }],
      action: useApi().uploadImageUrl,
      method: 'post',
      fieldName: 'files',
      headers: {
        Authorization: useBaseUserStore().token,
      },
      baseUrl: useApi().baseUrl,
      multiple: false,
      onSuccess(data) {
        console.log(data.data[0]);
        tableRef.value?.tableForm?.setOneFormState('bgImg', data.data[0]);
      },
    },

  ];
});


const operates: any[] = [];

const loading = ref(false);


const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  try {
    loading.value = true;
    const res = await useApi().areatypeApi.listAreaTypeareatype();
    const tableData: JuouProTableData = {
      total: res.data.data?.length || 0,
      data: res.data.data || [],
    };

    return tableData;
  } finally {
    loading.value = false;
  }
});

const formatDeleteInfo = (row: any) => `您正在进行删除(${row.name})操作，请谨慎操作`;


const addFun = useBaseAsync(async(data: any) => {
  await useApi().areatypeApi.addAreaTypeareatype(data);
  ElMessage.success('添加成功');
});
const editFun = useBaseAsync(async(data: any) => {
  await useApi().areatypeApi.updateAreaTypeareatype(data);
  ElMessage.success('修改成功');
});
const deleteFun = useBaseAsync(async(data: any) => {
  await useApi().areatypeApi.delAreaTypeareatype(data.id);
  ElMessage.success('删除成功');
});

init();

watchEffect(() => {
  tableRef.value?.refresh();
});

</script>
<style lang="scss" scoped>
</style>
