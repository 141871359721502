<template>
  <el-dialog v-model="visible" title="定时设置" draggable width="80vw">
    <JuouProTable
      ref="tableRef"
      :columns="columns"
      :get-data="getData"
      :on-add="addFun"
      :on-delete="deleteFun"
      :loading="loading"
      :is-dangder-delete="true"
      :format-dangder-delete-info="formatDeleteInfo"
      row-key="id"
      :operates="operates"
      :add-init-values="addInitValue"
      :codition-init-values="conditionsInitValue"
    />
  </el-dialog>
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  useBaseAsync,
  JuouProForm,
  JuouProFormItem,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';

const currentDeviceId = ref<number>();
const visible = ref(false);

const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();


const addInitValue = {

};
const conditionsInitValue = {
};


const modOptions = [
  {
    label: '打开',
    value: 'open',
  },
  {
    label: '关闭',
    value: 'close',
  },
];


const init = useBaseAsync(() => {
  columns.value = [
    {
      name: 'mod',
      label: '状态',
      type: 'select',
      options: modOptions,
      rule: [{ required: true }],
    },
    {
      name: 'base',
      label: '基准时间',
      type: 'datepick',
      pickType: 'datetime',
      rule: [{ required: true }],
      hideTable: true,
    },
    {
      name: 'createTime',
      label: '基准时间',
      type: 'string',
      hideCreate: true,
      hideEdit: true,
    },
    {
      name: 'interval',
      label: '间隔时间（s）',
      type: 'numberStr',
      rule: [{ required: true }],
      hideTable: true,
    },
    {
      name: 'ruleInterval',
      label: '间隔时间（s）',
      type: 'numberStr',
      hideCreate: true,
      hideEdit: true,
    },
    {
      name: 'ruleCurrent',
      label: '已执行次数',
      type: 'numberStr',
      hideCreate: true,
      hideEdit: true,
    },
    {
      name: 'ruleNextTime',
      label: '下次执行时间',
      type: 'string',
      hideCreate: true,
      hideEdit: true,
    },
  ];
});


const operates: any[] = [];

const loading = ref(false);


const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  try {
    if (!currentDeviceId.value) {
      return {
        total: 0,
        data: [],
      };
    }
    loading.value = true;
    const res = await useApi().deviceApi.listTimerDeviceSetModDevice(currentDeviceId.value);
    const tableData: JuouProTableData = {
      total: res.data.data?.length || 0,
      data: res.data.data?.map((item: any) => ({
        ...item,
        mod: item.name.split('-')[3],
      })) || [],
    };

    return tableData;
  } finally {
    loading.value = false;
  }
});

const formatDeleteInfo = (row: any) => '您正在进行删除操作，请谨慎操作';


const addFun = useBaseAsync(async(data: any) => {
  if (!currentDeviceId.value) {
    return;
  }
  data.deviceId = currentDeviceId.value;
  await useApi().deviceApi.timerDeviceSetModDevice(data);
  ElMessage.success('添加成功');
});

/* const updateFun = useBaseAsync(async(data: any) => {
  await useApi().deviceApi.updateDeviceDevice(data);
  ElMessage.success('修改成功');
}); */
const deleteFun = useBaseAsync(async(data: any) => {
  await useApi().deviceApi.deleteTimerDeviceSetModDevice(data.name);
  ElMessage.success('删除成功');
});

init();

defineExpose({
  show: (deviceId: number) => {
    currentDeviceId.value = deviceId;
    tableRef.value?.refresh();
    visible.value = true;
  },
});

</script>
<style lang="scss" scoped>
</style>
