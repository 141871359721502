<template>
  <div>
    <StoreRecordStatistic @filter="(data) => currentTimeRange = data.timeRange"/>
    <div class="juou-card" style="margin-top: 16px;">

      <JuouProTable
        ref="tableRef"
        title="门店记录"
        :paginations="{ pageSize: 10 }"
        :columns="columns"
        :get-data="getData"
        :loading="loading"
        :is-dangder-delete="true"
        :format-dangder-delete-info="formatDeleteInfo"
        row-key="id"
        :operates="operates"
        :add-init-values="addInitValue"
        :codition-init-values="conditionsInitValue"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  useBaseAsync,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { ref, watch, watchEffect } from 'vue';
import dayjs from 'dayjs';
import StoreDetail from '@/components/StoreDetail.vue';
import { useStore } from '@/store/store';
import { getTodaySec, todaySecToDayjs } from '@/utils';
import StoreRecordStatistic from '@/components/StoreRecordStatistic.vue';

const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();
const store = useStore();
const hiddenOption = [
  {
    value: false,
    label: '显示',
  },
  {
    value: true,
    label: '隐藏',
  },
];

const addInitValue = {

};
const conditionsInitValue = {
};

// eslint-disable-next-line max-lines-per-function
const init = useBaseAsync(() => {
  columns.value = [

    /* {
      name: 'timeRange',
      label: '时间范围',
      type: 'datepick',
      pickType: 'datetimerange',
      isCondition: true,
      order: 0,
      hideTable: true,
    }, */
    {
      name: 'phone',
      label: '手机号',
      type: 'numberStr',
      isCondition: true,
    },
    {
      name: 'level',
      label: '记录时等级',
      type: 'number',
      isCondition: true,
      render: ({ row: { level } }: any) => `V${level}`,
    },
    {
      name: 'storeDeviceName',
      label: '门店设备名称',
      type: 'string',
      isCondition: true,
    },
    {
      name: 'msg',
      label: '记录信息',
      type: 'string',
      isCondition: true,
    },
    {
      name: 'meta',
      label: '元数据',
      type: 'string',
      width: 80,
    },

    {
      name: 'price',
      label: '消费金额',
      type: 'string',
      width: 80,
    },
    {
      name: 'createTime',
      label: '记录时间',
      type: 'string',
      hideCreate: true,
      hideEdit: true,
      render: ({ row }: any) => dayjs(row.createTime).format('YYYY年MM月DD日 HH:mm:ss'),

    },

  ];
});


const operates: any[] = [];

const loading = ref(false);
const currentTimeRange = ref([dayjs().format('YYYY-MM-DD 00:00:00'), dayjs().format('YYYY-MM-DD 23:59:59')]);


const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  if (!store.currentStoreId) {
    return {
      total: 0,
      data: [],
    };
  }
  try {
    loading.value = true;
    const res = await useApi().recordApi.pageRecordRecord({
      conditions: Object.entries(filter.conditions).reduce((conditions, [key, val]: any) => {
        if (val === null || val === undefined || val === '') {
          return conditions;
        }

        /* if (key === 'timeRange') {
          conditions.start = val?.[0] || undefined;
          conditions.end = val?.[1] || undefined;
          return conditions;
        } */
        conditions[key] = val;
        return conditions;
      }, {
        storeId: store.currentStoreId,
        start: currentTimeRange.value[0] || undefined,
        end: currentTimeRange.value[1] || undefined,
      } as any),
      pageQuery: {
        page: filter.paginations.page - 1,
        pageSize: filter.paginations.pageSize,
      },


    });
    const tableData: JuouProTableData = {
      total: res.data.data?.total || 0,
      data: res.data.data?.data || [],
    };
    tableData.data = tableData.data.map((item) => ({
      ...item,
    }));
    return tableData;
  } finally {
    loading.value = false;
  }
});

const formatDeleteInfo = (row: any) => `您正在进行删除(${row.name})操作，请谨慎操作`;

init();

watchEffect(() => {
  tableRef.value?.refresh();
});

</script>
<style lang="scss" scoped>
</style>
