<template>
  <div>
    <StoreDetail/>
    <div class="juou-card" style="margin-top: 16px;">

      <JuouProTable
        ref="tableRef"
        title="门店账号"
        :paginations="{ pageSize: 10 }"
        :columns="columns"
        :get-data="getData"
        :on-add="addFun"
        :on-delete="deleteFun"
        :loading="loading"
        :is-dangder-delete="true"
        :format-dangder-delete-info="formatDeleteInfo"
        row-key="id"
        :operates="operates"
        :add-init-values="addInitValue"
        :codition-init-values="conditionsInitValue"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  useBaseAsync,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { ref, watchEffect } from 'vue';
import dayjs from 'dayjs';
import StoreDetail from '@/components/StoreDetail.vue';
import { useStore } from '@/store/store';

const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();
const store = useStore();


const addInitValue = {

};
const conditionsInitValue = {
};


const init = useBaseAsync(() => {
  columns.value = [
    {
      name: 'phone',
      label: '手机号',
      type: 'numberStr',
      isCondition: true,
      rule: [{ required: true }],
    },
    {
      name: 'password',
      label: '密码',
      type: 'password',
      hideTable: true,
      placeholder: '如果账号已存在，可不填写密码',
    },
    {
      name: 'createTime',
      label: '创建时间',
      type: 'string',
      width: 80,
      hideCreate: true,
      hideEdit: true,
      render: ({ row: { time } }: any) => dayjs(time).format('YYYY-MM-DD HH:mm:ss'),
    },

  ];
});


const operates: any[] = [];

const loading = ref(false);


const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  if (!store.currentStoreId) {
    return {
      total: 0,
      data: [],
    };
  }
  try {
    loading.value = true;
    const res = await useApi().storeManagerApi.pageStoreManagerStoreManager({
      conditions: Object.entries(filter.conditions).reduce((conditions, [key, val]) => {
        if (val === null || val === undefined || val === '') {
          return conditions;
        }
        conditions[key] = val;
        return conditions;
      }, {
        storeId: store.currentStoreId,
      } as any),
      pageQuery: {
        page: filter.paginations.page - 1,
        pageSize: filter.paginations.pageSize,
      },


    });
    const tableData: JuouProTableData = {
      total: res.data.data?.total || 0,
      data: res.data.data?.data || [],
    };

    return tableData;
  } finally {
    loading.value = false;
  }
});

const formatDeleteInfo = (row: any) => `您正在进行删除(${row.phone})操作，请谨慎操作`;


const addFun = useBaseAsync(async(data: any) => {
  if (!store.currentStoreId) {
    ElMessage.error('请先切换选中一个门店');
    return;
  }
  await useApi().storeManagerApi.addStoreManagerStoreManager({
    ...data,
    storeId: store.currentStoreId,
  });
  ElMessage.success('添加成功');
});


const deleteFun = useBaseAsync(async(data: any) => {
  if (!store.currentStoreId) {
    ElMessage.error('请先切换选中一个门店');
    return;
  }
  await useApi().storeManagerApi.deleteStoreManagerStoreManager(data.phone, store.currentStoreId);
  ElMessage.success('删除成功');
});


init();

watchEffect(() => {
  tableRef.value?.refresh();
});

</script>
<style lang="scss" scoped>
</style>
