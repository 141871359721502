<template>
  <div class="juou-card store-record-statistic " v-loading="loading">
    <div class="store-record-statistic-top">
      <div>

        <JuouProForm ref="filterRef" :items="filter" is-inline hide-cancel hide-ok @change="handleFilterChange"
         :init-form-value="{ timeRange:  currentTimeRange }"/>
      </div>
      <div class="btns">

      </div>
    </div>

    <div class="store-record-statistic-body" v-loading="statisticLoading">
     <!--  <div class="store-record-statistic-body-item">
        <span>￥{{statistic.netPrice}}</span>
        <span>净收入</span>
      </div>
      <el-divider style="height: 70%;" direction="vertical"/>
      <div class="store-record-statistic-body-item">
        <span>￥{{statistic.allPrice}}</span>
        <span>总计入账</span>
      </div>
      <el-divider style="height: 70%;" direction="vertical"/>
      <div class="store-record-statistic-body-item">
        <span>￥{{statistic.allCashPledgePrice}}</span>
        <span>押金入账</span>
      </div>
      <el-divider style="height: 70%;" direction="vertical"/>
      <div class="store-record-statistic-body-item">
        <span>￥{{statistic.allBackPrice}}</span>
        <span>总退款</span>
      </div>


      <div class="store-record-statistic-body-item">
        <span>￥{{statistic.allPayPrice}}</span>
        <span>充值套餐</span>
      </div>
      <el-divider style="height: 70%;" direction="vertical"/>
      <div class="store-record-statistic-body-item">
        <span>￥{{statistic.allHourPrice}}</span>
        <span>小时套餐</span>
      </div>
      <el-divider style="height: 70%;" direction="vertical"/>
      <div class="store-record-statistic-body-item">
        <span>￥{{statistic.allMeituanPrice}}</span>
        <span>美团套餐</span>
      </div>
      <el-divider style="height: 70%;" direction="vertical"/>
      <div class="store-record-statistic-body-item">
        <span>￥{{statistic.allDouyinPrice}}</span>
        <span>抖音套餐</span>
      </div> -->
    </div>

  </div>
</template>
<script lang="ts" setup>
import { useBaseUserStore, JuouProForm, JuouProFormItem, useRoute, useBaseAsync, vJuouOperate } from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import defaultImg from '@/assets/store.jpg';
import { ElMessage } from 'element-plus';
import { getCurrentUrl } from '@/utils';
import { cloneDeep } from 'lodash';
import { useStore } from '@/store/store';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');
const emit = defineEmits<{(e: 'filter', data: any): void;}>();

const filter = ref<JuouProFormItem[]>([]);
const filterRef = ref<InstanceType<typeof JuouProForm>>();

const route = useRoute();


const loading = ref(false);
const statisticLoading = ref(false);

const store = useStore();

const currentTimeRange = ref([dayjs().format('YYYY-MM-DD 00:00:00'), dayjs().format('YYYY-MM-DD 23:59:59')]);

/* const statistic = ref({
  netPrice: '0',
  allPrice: '0',
  allBackPrice: '0',
  allCashPledgePrice: '0',
  allPayPrice: '0',
  allHourPrice: '0',
  allMeituanPrice: '0',
  allDouyinPrice: '0',
});

const getStatistic = useBaseAsync(async() => {
  if (!store.currentStoreId) {
    return;
  }
  try {
    statisticLoading.value = true;
    const res = await useApi().recordApi.statisticBillBill({
      storeId: store.currentStoreId,
      start: currentTimeRange.value[0],
      end: currentTimeRange.value[1],
    });
    console.log(res);
    statistic.value = res.data.data || {};
  } finally {
    statisticLoading.value = false;
  }
});
 */

const handleFilterChange = (data: any) => {
  store.setCurrentStoreId(data.selectStore);
  currentTimeRange.value = data.timeRange;
  // getStatistic();
  emit('filter', data);
};


// eslint-disable-next-line max-lines-per-function
const init = useBaseAsync(async() => {
  try {
    loading.value = true;
    const [storeDetailRes] = await Promise.all([useApi().storeManagerApi.listManagerStoreStoreManager()]);
    const details = storeDetailRes.data.data || [];
    filter.value = [
      {
        name: 'selectStore',
        label: '切换门店',
        type: 'select',
        options: details.map((item: any) => ({
          value: item.id,
          label: item.name,
        })),
        clearable: false,
      },
      {
        name: 'timeRange',
        label: '时间范围',
        type: 'datepick',
        pickType: 'datetimerange',
        fastPick: [
          {
            name: '今日',
            handler: (formdata) => {
              formdata['timeRange'] = [
                dayjs().format('YYYY-MM-DD 00:00:00'),
                dayjs().format('YYYY-MM-DD 23:59:59'),
              ];
            },
            dateChangeHandler: ([start, end]) => {
              const [startDay, startTime] = start.split(' ');
              const [endDay, endTime] = end.split(' ');
              return startDay === endDay && startTime === '00:00:00' && endTime === '23:59:59' && dayjs().format('YYYY-MM-DD') === startDay;
            },
          },
          {
            name: '昨日',
            handler: (formdata) => {
              formdata['timeRange'] = [
                dayjs().subtract(1, 'd')
                  .format('YYYY-MM-DD 00:00:00'),
                dayjs().subtract(1, 'd')
                  .format('YYYY-MM-DD 23:59:59'),
              ];
            },
            dateChangeHandler: ([start, end]) => {
              const [startDay, startTime] = start.split(' ');
              const [endDay, endTime] = end.split(' ');
              return startDay === endDay && startTime === '00:00:00' && endTime === '23:59:59' && dayjs().subtract(1, 'd')
                .format('YYYY-MM-DD') === startDay;
            },
          },
          {
            name: '本周',
            handler: (formdata) => {
              formdata['timeRange'] = [
                dayjs().startOf('week')
                  .format('YYYY-MM-DD 00:00:00'),
                dayjs().format('YYYY-MM-DD 23:59:59'),
              ];
            },
            dateChangeHandler: ([start, end]) => {
              const [startDay, startTime] = start.split(' ');
              const [endDay, endTime] = end.split(' ');
              return dayjs().format('YYYY-MM-DD') === endDay && dayjs(end).startOf('week')
                .format('YYYY-MM-DD') === startDay && startTime === '00:00:00' && endTime === '23:59:59';
            },
          },
          {
            name: '本月',
            handler: (formdata) => {
              formdata['timeRange'] = [
                dayjs().startOf('month')
                  .format('YYYY-MM-DD 00:00:00'),
                dayjs().format('YYYY-MM-DD HH:mm:ss'),
              ];
            },
            dateChangeHandler: ([start, end]) => {
              const [startDay, startTime] = start.split(' ');
              const [endDay, endTime] = end.split(' ');
              return dayjs().format('YYYY-MM-DD') === endDay && dayjs(end).startOf('month')
                .format('YYYY-MM-DD') === startDay && startTime === '00:00:00';
            },
          },
          {
            name: '上月',
            handler: (formdata) => {
              formdata['timeRange'] = [
                dayjs().subtract(1, 'month')
                  .startOf('month')
                  .format('YYYY-MM-DD 00:00:00'),
                dayjs().subtract(1, 'month')
                  .endOf('month')
                  .format('YYYY-MM-DD HH:mm:ss'),
              ];
            },
            dateChangeHandler: ([start, end]) => {
              const [startDay, startTime] = start.split(' ');
              const [endDay, endTime] = end.split(' ');
              return dayjs().subtract(1, 'month')
                .startOf('month')
                .format('YYYY-MM-DD') === startDay && dayjs().subtract(1, 'month')
                .endOf('month')
                .format('YYYY-MM-DD') === endDay && startTime === '00:00:00' && endTime === '23:59:59';
            },
          },
        ],
      },
    ];
    if (details.length && !details.find((item: any) => item.id === store.currentStoreId)) {
      filterRef.value?.setOneFormState('selectStore', details[0].id);
    }
  } finally {
    loading.value = false;
  }
});


init();


</script>
<style lang="scss" scoped>
label {
  display: inline-flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex: 0 0 auto;
  font-size: var(--el-form-label-font-size);
  color: var(--el-text-color-regular);
  height: 32px;
  line-height: 32px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}

.store-record-statistic {

  &-top {
    display: flex;
    justify-content: space-between;
  }

  &-body {
    //margin: 16px 0;
    display: grid;
    grid-template-columns: 24% 1fr 24% 1fr 24% 1fr 24%;
    //grid-template-rows: 100px 100px;
    align-items: center;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;

      span:first-child {
        font-size: 36px;
        font-weight: 600;
        color: #0389dd;
      }

      &-value {
        color: #0389dd;
        font-size: 24px;
        margin-bottom: 8px;
      }
    }
  }


}
</style>
