<template>
  <el-dialog width="1100" title="查看小时券" v-model="visible" draggable>
    <JuouProTable
      v-if="visible"
      ref="tableRef"
      :paginations="{ pageSize: 10 }"
      :columns="columns"
      :get-data="getData"
      :loading="loading"
      :is-dangder-delete="true"
      :format-dangder-delete-info="formatDeleteInfo"
      row-key="id"
      :operates="operates"
      :add-init-values="addInitValue"
      :codition-init-values="conditionsInitValue"
      :selection="true"
      :batch-operates="batchOperates"
      >
      <div class="ops">
        <div class="ops-left">
          <el-button
            type="primary"
            @click="() => {bathGenDialogRef?.setFormState({...batchGenInitValue});bathGenDialogRef?.showDialog()}"
            >批量生成小时券</el-button>
          <el-button type="primary"
            @click="() => {genDialogRef?.setFormState({...genInitValue});genDialogRef?.showDialog()}"
            >发放单个小时券</el-button>
        </div>
        <div class="ops-right">
          <el-button type="primary" @click="handleExcel">导出小时券</el-button>
        </div>
      </div>
    </JuouProTable>
    <JuouProForm
      ref="bathUpdateDialogRef"
      title="批量修改小时券"
      :on-ok="batchUpdateHourTicket"
      :isDialog="true"
      :items="batchUpdateItems"
      />
    <JuouProForm
      ref="bathGenDialogRef"
      title="批量生成小时券"
      :on-ok="batchGeneratorHourTicket"
      :isDialog="true"
      :items="batchGenItems"
      :init-form-value="batchGenInitValue"
      />
    <JuouProForm
      ref="genDialogRef"
      title="发放小时券"
      :on-ok="generatorHourTicket"
      :isDialog="true"
      :items="genItems"
      :init-form-value="genInitValue"
      />
  </el-dialog>

</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  JuouProForm,
  JuouProFormItem,
  useBaseAsync,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { ref, watchEffect } from 'vue';
import dayjs from 'dayjs';
import StoreDetail from '@/components/StoreDetail.vue';
import { useStore } from '@/store/store';
import { getTodaySec, todaySecToDayjs } from '@/utils';
import * as xlsx from 'xlsx';
import FileSaver from 'file-saver';

const packageId = ref<number>();
const visible = ref(false);

const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();
const store = useStore();

const bathUpdateDialogRef = ref<InstanceType<typeof JuouProForm>>();
const bathGenDialogRef = ref<InstanceType<typeof JuouProForm>>();
const genDialogRef = ref<InstanceType<typeof JuouProForm>>();

const addInitValue = {

};
const conditionsInitValue = {
};

const batchGenItems: JuouProFormItem[] = [
  {
    name: 'count',
    label: '数量',
    type: 'number',
    rule: [{ required: true }],
  },
  {
    name: 'expireAt',
    label: '过期时间',
    type: 'datepick',
    pickType: 'datetime',
  },
];

const batchGenInitValue = {
  count: 1,
  expireAt: undefined,
};

const batchUpdateItems: JuouProFormItem[] = [
  {
    name: 'used',
    label: '是否核销',
    type: 'swtich',
    rule: [{ required: true }],
  },
  {
    name: 'expireAt',
    label: '过期时间',
    type: 'datepick',
    pickType: 'datetime',
  },
];


const genItems: JuouProFormItem[] = [
  {
    name: 'phone',
    label: '客户手机号',
    type: 'numberStr',
    rule: [{ required: true }],
  },
  {
    name: 'expireAt',
    label: '过期时间',
    type: 'datepick',
    pickType: 'datetime',
  },
];


const genInitValue = {
  phone: undefined,
  expireAt: undefined,
};

const currentSelectTickets = ref<any[]>([]);

const batchOperates = [
  {
    name: '批量修改',
    key: 'batUpdate',
    handler: (rows: any[]) => {
      console.log(rows);
      currentSelectTickets.value = rows;
      bathUpdateDialogRef.value?.setFormState({
        used: false,
        expireAt: undefined,
      });
      bathUpdateDialogRef.value?.showDialog();
    },
  },
];
// eslint-disable-next-line max-lines-per-function
const init = useBaseAsync(() => {
  columns.value = [
    {
      name: 'id',
      label: '券码',
      type: 'numberStr',
      isCondition: true,
    },
    {
      name: 'used',
      label: '是否核销',
      type: 'swtich',
      isCondition: true,
      width: 80,
    },
    {
      name: 'phone',
      label: '手机号',
      type: 'numberStr',
      isCondition: true,
    },

    {
      name: 'expireAt',
      label: '过期时间',
      type: 'string',
      hideCreate: true,
      hideEdit: true,
      render: ({ row }: any) => (row.expireAt ? dayjs(row.expireAt).format('YYYY年MM月DD日 HH:mm:ss') : '-'),

    },

    {
      name: 'createTime',
      label: '创建时间',
      type: 'string',
      hideCreate: true,
      hideEdit: true,
      render: ({ row }: any) => dayjs(row.createTime).format('YYYY年MM月DD日 HH:mm:ss'),

    },

  ];
});


const operates: any[] = [];

const loading = ref(false);


const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  if (!packageId.value) {
    return {
      total: 0,
      data: [],
    };
  }
  try {
    loading.value = true;
    const res = await useApi().hourPackageApi.pageHourTicketHourPackage({
      conditions: Object.entries(filter.conditions).reduce((conditions, [key, val]) => {
        if (val === null || val === undefined || val === '') {
          return conditions;
        }
        conditions[key] = val;
        return conditions;
      }, {
        packageId: packageId.value,
      } as any),
      pageQuery: {
        page: filter.paginations.page - 1,
        pageSize: filter.paginations.pageSize,
      },


    });
    const tableData: JuouProTableData = {
      total: res.data.data?.total || 0,
      data: res.data.data?.data || [],
    };
    return tableData;
  } finally {
    loading.value = false;
  }
});

const formatDeleteInfo = (row: any) => `您正在进行删除(${row.name})操作，请谨慎操作`;

// 批量修改小时券
const batchUpdateHourTicket = useBaseAsync(async(data: any) => {
  if (!packageId.value) {
    ElMessage.error('未选择小时券');
    return;
  }
  if (!currentSelectTickets.value.length) {
    ElMessage.error('至少选择一个小时券');
    return;
  }
  await useApi().hourPackageApi.batchUpdateTicketHourPackage({
    ticketIds: currentSelectTickets.value.map((item) => item.id),
    expireAt: data.expireAt && dayjs(data.expireAt).toDate(),
  });
  ElMessage.success('修改成功');
  tableRef.value?.refresh();
});

// 批量生成小时券
const batchGeneratorHourTicket = useBaseAsync(async(data: any) => {
  if (!packageId.value) {
    ElMessage.error('未选择小时券');
    return;
  }
  await useApi().hourPackageApi.batchGeneratorHourTicketHourPackage({
    ...data,
    packageId: packageId.value,
    expireAt: data.expireAt && dayjs(data.expireAt).toDate(),
  });
  ElMessage.success('生成成功');
  tableRef.value?.refresh();
});

// 发放小时券
const generatorHourTicket = useBaseAsync(async(data: any) => {
  if (!packageId.value) {
    ElMessage.error('未选择小时券');
    return;
  }
  await useApi().hourPackageApi.generatorHourTicketHourPackage({
    ...data,
    packageId: packageId.value,
    expireAt: data.expireAt && dayjs(data.expireAt).toDate(),
  });
  ElMessage.success('生成成功');
  tableRef.value?.refresh();
});


const handleExcel = useBaseAsync(async() => {
  if (!packageId.value) {
    ElMessage.error('未选择小时券');
    return;
  }
  const res = await useApi().hourPackageApi.listHourTicketHourPackage({
    conditions: Object.entries(tableRef.value?.filter?.conditions || {}).reduce((conditions, [key, val]) => {
      if (val === null || val === undefined || val === '') {
        return conditions;
      }
      conditions[key] = val;
      return conditions;
    }, {
      packageId: packageId.value,
    } as any),
  });
  console.log(res.data.data);
  const worksheet = xlsx.utils.json_to_sheet(res.data.data.map((item: any) => ({
    券码: String(item.id),
    是否核销: item.used ? '是' : '否',
    手机号: String(item.phone || ''),
    过期时间: item.expireAt ? dayjs(item.expireAt).format('YYYY-MM-DD HH:mm:ss') : '-',
    创建时间: dayjs(item.createTime).format('YYYY-MM-DD HH:mm:ss'),
  })));
  const workbook = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(workbook, worksheet, 'Dates');
  const wbout = xlsx.write(workbook, {
    bookType: 'xlsx',
    bookSST: true,
    type: 'array',
  });
  try {
    FileSaver.saveAs(
      new Blob([wbout], {
        type: 'application/octet-stream',
      }),
      '小时券.xlsx',
    );
  } catch (error) {
    if (typeof console !== 'undefined') {
      console.log(error, wbout);
    }
  }
});


init();


defineExpose({
  show: (_packageId: number) => {
    packageId.value = _packageId;
    visible.value = true;
  },
});

</script>
<style lang="scss" scoped>
.ops {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  > * {
    display: flex;
    align-items: center;
    gap: 8px;
  }

}
</style>
