import {
  DeviceApi,
  StoreApi,
  StoreManagerApi,
  StoreCustomerApi,
  AreatypeApi,
  StoreDeviceApi,
  Configuration,
  PayPackageApi,
  HourPackageApi,
  BillApi,
  RecordApi,
  MeituanPackageApi,
  DouyinPackageApi,
  UserwxApi,
} from '@/api';
import { axios } from 'juou-managebase-ui';

interface BaseApi {
  baseUrl: string;
  uploadImageUrl: string;
  deviceApi: DeviceApi;
  storeApi: StoreApi;
  storeManagerApi: StoreManagerApi;
  storeCustomerApi: StoreCustomerApi;
  areatypeApi: AreatypeApi;
  storeDeviceApi: StoreDeviceApi;
  payPackageApi: PayPackageApi;
  hourPackageApi: HourPackageApi;
  billApi: BillApi;
  recordApi: RecordApi;
  meituanPackageApi: MeituanPackageApi;
  douyinPackageApi: DouyinPackageApi;
  userwxApi: UserwxApi;
}

const api: BaseApi = {

} as any;

export const initApi = (basePath: string) => {
  const apiConfig = new Configuration({
    basePath,
  });
  api.baseUrl = basePath;
  api.uploadImageUrl = '/upload/image';
  api.deviceApi = new DeviceApi(apiConfig, basePath, axios);
  api.storeApi = new StoreApi(apiConfig, basePath, axios);
  api.storeManagerApi = new StoreManagerApi(apiConfig, basePath, axios);
  api.storeCustomerApi = new StoreCustomerApi(apiConfig, basePath, axios);
  api.storeDeviceApi = new StoreDeviceApi(apiConfig, basePath, axios);
  api.areatypeApi = new AreatypeApi(apiConfig, basePath, axios);
  api.payPackageApi = new PayPackageApi(apiConfig, basePath, axios);
  api.hourPackageApi = new HourPackageApi(apiConfig, basePath, axios);
  api.billApi = new BillApi(apiConfig, basePath, axios);
  api.recordApi = new RecordApi(apiConfig, basePath, axios);
  api.meituanPackageApi = new MeituanPackageApi(apiConfig, basePath, axios);
  api.douyinPackageApi = new DouyinPackageApi(apiConfig, basePath, axios);
  api.userwxApi = new UserwxApi(apiConfig, basePath, axios);
};

export const useApi = () => api;

