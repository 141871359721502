<template>
  <div>
    <StoreDetail/>
    <div class="juou-card" style="margin-top: 16px;">

      <JuouProTable
        ref="tableRef"
        title="美团套餐"
        :columns="columns"
        :get-data="getData"
        :on-edit="updateFun"
        :loading="loading"
        :is-dangder-delete="true"
        :format-dangder-delete-info="formatDeleteInfo"
        row-key="id"
        :operates="operates"
        :add-init-values="addInitValue"
        :codition-init-values="conditionsInitValue"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  useBaseAsync,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { ref, watchEffect } from 'vue';
import dayjs from 'dayjs';
import StoreDetail from '@/components/StoreDetail.vue';
import { useStore } from '@/store/store';
import { getTodaySec, todaySecToDayjs } from '@/utils';

const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();
const store = useStore();


const addInitValue = {

};
const conditionsInitValue = {
};


// eslint-disable-next-line max-lines-per-function
const init = useBaseAsync(async() => {
  const [areaTypeRes] = await Promise.all([useApi().areatypeApi.listAreaTypeareatype()]);
  const areaTypeOptions
    = areaTypeRes.data.data?.map((item: any) => ({
      value: item.id,
      label: item.name,
    })) || [];
  columns.value = [
    {
      name: 'title',
      label: '团购名称',
      type: 'string',
      hideEdit: true,
    },
    {
      name: 'typeId',
      label: '类型',
      type: 'searchSelect',
      rule: [{ required: true }],
      options: areaTypeOptions,
    },
    {
      name: 'time',
      label: '服务时间',
      type: 'timepick',

      rule: [{ required: true }],
      render: ({ row }: any) => (row.time ? dayjs(row.time).format('HH时mm分ss秒') : ''),
    },
    {
      name: 'userange',
      label: '可用时间范围',
      type: 'string',
      hideCreate: true,
      hideEdit: true,
    },
    {
      name: 'rangeStart',
      label: '可用开始时间',
      type: 'timepick',
      hideTable: true,
    },
    {
      name: 'rangeEnd',
      label: '可用结束时间',
      type: 'timepick',
      hideTable: true,
    },
  ];
});


const operates: any[] = [];

const loading = ref(false);


const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  if (!store.currentStoreId) {
    return {
      total: 0,
      data: [],
    };
  }
  try {
    loading.value = true;
    const res = await useApi().meituanPackageApi.listMeituanPackageMeituanPackage(store.currentStoreId);
    const tableData: JuouProTableData = {
      total: res.data.data?.length || 0,
      data: res.data.data || [],
    };
    tableData.data = tableData.data.map((item) => ({
      ...item,
      time: item.sec && todaySecToDayjs(item.sec).toDate(),
      rangeStart: item.userange && dayjs(item.userange.split('-')[0]).toDate(),
      rangeEnd: item.userange && dayjs(item.userange.split('-')[1]).toDate(),
    }));
    return tableData;
  } finally {
    loading.value = false;
  }
});

const formatDeleteInfo = (row: any) => `您正在进行删除(${row.title})操作，请谨慎操作`;


const updateFun = useBaseAsync(async(data: any) => {
  console.log(data.sec);
  if (data.rangeStart && data.rangeEnd) {
    const start = dayjs(data.rangeStart).format('HH:mm:ss');
    const end = dayjs(data.rangeEnd).format('HH:mm:ss');
    if (start < end) {
      data.userange = `${start}-${end}`;
    }
  }

  await useApi().meituanPackageApi.updateMeituanMeituanPackage({
    ...data,
    sec: getTodaySec(dayjs(data.time)),
  });
  ElMessage.success('修改成功');
});


init();

watchEffect(() => {
  tableRef.value?.refresh();
});

</script>
<style lang="scss" scoped>
</style>
