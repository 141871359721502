<template>
  <div>
    <StoreBillStatistic ref="billStatisticRef" @filter="(data) => currentTimeRange = data.timeRange"/>
    <div class="juou-card" style="margin-top: 16px;">

      <JuouProTable
        ref="tableRef"
        title="财务账单列表"
        :paginations="{ pageSize: 10 }"
        :columns="columns"
        :get-data="getData"
        :loading="loading"
        :is-dangder-delete="true"
        :format-dangder-delete-info="formatDeleteInfo"
        row-key="id"
        :operates="operates"
        :add-init-values="addInitValue"
        :codition-init-values="conditionsInitValue"
        :operation-width="120"
      />
    </div>
    <JuouProForm is-dialog title="退款" ref="backBillDialogRef" :items="backBillItems" @ok="handleBackBillWx" />
  </div>
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  JuouProForm,
  JuouProFormItem,
  useBaseAsync,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { ref, watchEffect } from 'vue';
import dayjs from 'dayjs';
import StoreDetail from '@/components/StoreDetail.vue';
import { useStore } from '@/store/store';
import { getTodaySec, todaySecToDayjs } from '@/utils';
import StoreBillStatistic from '@/components/StoreBillStatistic.vue';

const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();
const backBillDialogRef = ref<InstanceType<typeof JuouProForm>>();
const billStatisticRef = ref<InstanceType<typeof StoreBillStatistic>>();
const store = useStore();

const currentBillId = ref<number>();

const backBillItems: JuouProFormItem[] = [
  {
    name: 'backPrice',
    label: '退款金额',
    type: 'string',
    rule: [{ required: true }],
  },
  {
    name: 'reason',
    label: '退款原因',
    type: 'textaera',
  },
];


const statusOption = [
  {
    value: 'COMPLETE',
    label: '完成',
  },
  {
    value: 'PREP',
    label: '待支付',
  },
  {
    value: 'FAILED',
    label: '失败',
  },
];

const addInitValue = {

};
const conditionsInitValue = {
  status: 'COMPLETE',
};

const billTypeOption = [
  {
    value: 'PAY',
    label: '充值套餐',
  },
  {
    value: 'CASHPLEDGE',
    label: '押金入账',
  },
  {
    value: 'HOUR',
    label: '小时套餐',
  },
  {
    value: 'MEITUAN',
    label: '美团套餐',
  },
  {
    value: 'DOUYIN',
    label: '抖音套餐',
  },
];
// eslint-disable-next-line max-lines-per-function
const init = useBaseAsync(() => {
  columns.value = [

    /* {
      name: 'timeRange',
      label: '时间范围',
      type: 'datepick',
      pickType: 'datetimerange',
      isCondition: true,
      order: 0,
      hideTable: true,
    }, */
    {
      name: 'id',
      label: '订单编号',
      type: 'numberStr',
      isCondition: true,
      hideCreate: true,
      hideEdit: true,
    },
    {
      name: 'phone',
      label: '客户手机号',
      type: 'numberStr',
      isCondition: true,
    },
    {
      name: 'type',
      label: '账单类型',
      type: 'select',
      options: billTypeOption,
      isCondition: true,
      render: ({ row: { type } }: any) => {
        const typeVal = billTypeOption.find((item: any) => item.value === type);
        if (!typeVal) {
          return '-';
        }
        return typeVal.label;
      },
    },
    {
      name: 'source',
      label: '数据来源',
      type: 'string',
    },

    /* {
      name: 'meta',
      label: '元数据',
      type: 'string',
      width: 80,
    }, */
    {
      name: 'createTime',
      label: '时间',
      type: 'datepick',
      pickType: 'datetime',
      render: ({ row }: any) => dayjs(row.createTime).format('YYYY年MM月DD日 HH:mm:ss'),
    },
    {
      name: 'price',
      label: '金额',
      type: 'numberStr',
      width: 80,
    },
    {
      name: 'backPrice',
      label: '退款金额',
      type: 'numberStr',
      width: 80,
    },
    {
      name: 'status',
      label: '状态',
      type: 'searchSelect',
      isCondition: true,
      options: statusOption,
      width: 80,
    },
  ];
});

const handleBackBillWx = useBaseAsync(async(row: any) => {
  await useApi().billApi.backBillWxBill({
    billId: currentBillId.value,
    ...row,
  });
  ElMessage.success('退款成功');
  tableRef.value?.refresh();
  billStatisticRef.value?.getStatistic();
});


const operates: any[] = [
  {
    key: 'back',
    name: '退款',
    handler: (row: any) => {
      console.log(row);
      currentBillId.value = row.id;
      backBillDialogRef.value?.reset();
      backBillDialogRef.value?.showDialog();
    },
  },
];

const loading = ref(false);
const currentTimeRange = ref([dayjs().format('YYYY-MM-DD 00:00:00'), dayjs().format('YYYY-MM-DD 23:59:59')]);


const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  if (!store.currentStoreId) {
    return {
      total: 0,
      data: [],
    };
  }
  try {
    loading.value = true;
    const res = await useApi().billApi.pageBillBill({
      conditions: Object.entries(filter.conditions).reduce((conditions, [key, val]: any) => {
        if (val === null || val === undefined || val === '') {
          return conditions;
        }

        /*  if (key === 'timeRange') {
          conditions.start = val?.[0] || undefined;
          conditions.end = val?.[1] || undefined;
          return conditions;
        } */
        conditions[key] = val;
        return conditions;
      }, {
        storeId: store.currentStoreId,
        start: currentTimeRange.value[0] || undefined,
        end: currentTimeRange.value[1] || undefined,
      } as any),
      pageQuery: {
        page: filter.paginations.page - 1,
        pageSize: filter.paginations.pageSize,
      },


    });
    const tableData: JuouProTableData = {
      total: res.data.data?.total || 0,
      data: res.data.data?.data || [],
    };
    tableData.data = tableData.data.map((item) => ({
      ...item,
      time: todaySecToDayjs(item.sec).toDate(),
      hideOp: {
        back: !['HOUR', 'PAY', 'CASHPLEDGE'].includes(item.type),
      },
    }));
    return tableData;
  } finally {
    loading.value = false;
  }
});

const formatDeleteInfo = (row: any) => `您正在进行删除(${row.name})操作，请谨慎操作`;


init();

watchEffect(() => {
  tableRef.value?.refresh();
});

</script>
<style lang="scss" scoped>
</style>
