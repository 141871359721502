<template>
  <div class="juou-card" v-loading="loading">
    <div class="store-detail-top">
      <div>
        <label>切换门店：</label>
        <el-select filterable :model-value="store.currentStoreId" @update:model-value="(v: any) => store.setCurrentStoreId(v)">
          <el-option v-for="op in storeDetailOptions" :key="op.value" :label="op.label" :value="op.value" />
        </el-select>
      </div>
      <div class="btns">
        <el-button v-juou-operate="'store_detail_edit'" @click="showEditDialog" type="primary">修改</el-button>
      </div>
    </div>

    <div class="store-detail-body">
      <el-card shadow="always" style="width: 200px; height: 140px; " body-style="padding:  8px">
        <el-image  style="height: 122px; width: 184px;"  fit="cover"
          :src="currentStoreDetail?.logo ? `${useApi().baseUrl}${currentStoreDetail?.logo}` : defaultImg" />
      </el-card>

      <div class="store-detail-body-info">
        <div v-if="currentStoreDetail?.headPrincipal">
          <span>总店负责人：</span>{{ currentStoreDetail?.headPrincipal }}
        </div>
        <div>
          <span>门店名称：</span>{{ currentStoreDetail?.name }}
        </div>
        <div>
          <span>门店电话：</span>{{ currentStoreDetail?.tel }}
        </div>
        <div>
          <span>门店地址：</span>{{ currentStoreDetail?.address }} {{ currentStoreDetail?.detailAddress ? `(${currentStoreDetail?.detailAddress})` : '' }}
        </div>
        <div>
          <span>
            美团：{{ currentStoreDetail?.meituan?.shopname || '未绑定' }}
            {{ currentStoreDetail?.meituan?.authExpiresAt && `(授权到期：${currentStoreDetail?.meituan.authExpiresAt})` }}
          </span>
          <el-button v-juou-operate="'store_detail_auth_meituan'" link type="primary" @click="handleAuth">授权</el-button>
        </div>
        <div>
          <span>
            抖音：{{ currentStoreDetail?.douyin?.shopname || '未绑定' }}
          </span>
          <el-button v-juou-operate="'store_detail_auth_meituan'" link type="primary" @click="handleAuthDouyin">授权</el-button>
        </div>
      </div>
    </div>
    <JuouProForm ref="editDialog" title="修改门店信息" :on-ok="handleUpdate" :isDialog="true" :items="items"/>
    <el-dialog title="选取门店地址" v-model="mapDialogVisible" width="400">
      <iframe id="mapPage" width="100%" height="600px" frameborder=0
          src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&policy=0&key=THZBZ-X7LRB-C7LUL-J5XY4-XABUT-WGF3N&referer=myapp"/>
    </el-dialog>
  </div>
</template>
<script lang="ts" setup>
import { useBaseUserStore, JuouProForm, JuouProFormItem, useRoute, useBaseAsync, vJuouOperate } from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import defaultImg from '@/assets/store.jpg';
import { ElMessage } from 'element-plus';
import { getCurrentUrl } from '@/utils';
import { cloneDeep } from 'lodash';
import { useStore } from '@/store/store';
import { sha256 } from 'js-sha256';

const route = useRoute();

const editDialog = ref<typeof JuouProForm>();
const mapDialogVisible = ref(false);

const loading = ref(false);

const storeDetails = ref<any[]>([]);
const store = useStore();
const storeDetailOptions = computed(() => storeDetails.value.map((item: any) => ({
  value: item.id,
  label: item.name,
})) || []);
const currentStoreDetail = computed(() => storeDetails.value.find((item) => item.id === store.currentStoreId));
const items: JuouProFormItem[] = [
  {
    name: 'logo',
    label: '门店图片',
    type: 'image',
    action: useApi().uploadImageUrl,
    method: 'post',
    fieldName: 'files',
    headers: {
      Authorization: useBaseUserStore().token,
    },
    baseUrl: useApi().baseUrl,
    multiple: false,
    onSuccess(data) {
      console.log(data.data[0]);
      editDialog.value?.setOneFormState('logo', data.data[0]);
    },
  },
  {
    type: 'numberStr',
    name: 'tel',
    label: '门店电话',
  },
  {
    type: 'link',
    name: 'address',
    label: '门店地址',
    placeholder: '（未选择地址，请点击选择）',
    onClick: () => {
      mapDialogVisible.value = true;
    },
  },
  {
    type: 'string',
    name: 'detailAddress',
    label: '详细地址',
  },
];


const location = ref<any>({
  longtitude: undefined,
  latitude: undefined,
});


const init = useBaseAsync(async() => {
  try {
    loading.value = true;
    const [storeDetailRes] = await Promise.all([useApi().storeManagerApi.listManagerStoreStoreManager()]);
    storeDetails.value = storeDetailRes.data.data || [];
    if (storeDetails.value.length && !storeDetails.value.find((item) => item.id === store.currentStoreId)) {
      store.setCurrentStoreId(storeDetails.value[0].id);
    }
  } finally {
    loading.value = false;
  }
});

const authMeituan = useBaseAsync(async(authCode: string) => {
  if (!store.currentStoreId) {
    ElMessage.error('当前未选择门店');
    return;
  }
  try {
    loading.value = true;

    await useApi().meituanPackageApi.authMeituanMeituanPackage({
      authCode,
      storeId: store.currentStoreId,
      redirectUrl: getCurrentUrl(),
    });
    await init();
    ElMessage.success('授权成功');
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
});

init();

const showEditDialog = () => {
  if (!currentStoreDetail.value) {
    ElMessage.error('当前未选择门店');
    return;
  }
  editDialog.value?.setFormState(cloneDeep(currentStoreDetail.value));
  editDialog.value?.showDialog();
};

const handleUpdate = useBaseAsync(async(data: any) => {
  if (!currentStoreDetail.value) {
    ElMessage.error('当前未选择门店');
    return;
  }

  const param = {
    ...currentStoreDetail.value,
    ...data,
  };

  if (location.value.longtitude && location.value.latitude) {
    param.geo = {
      ...location.value,
    };
  }

  await useApi().storeApi.updateStoreStore(param);
  ElMessage.success('更新成功');
  init();
});

if (route.query) {
  const { state, auth_code: authCode } = route.query as any;
  if (authCode && state) {
    store.setCurrentStoreId(state);
    authMeituan(authCode);
  }
}


const onChooseLocation = (event: any) => {
  // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
  const loc = event.data;
  // 防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
  if (loc && loc.module === 'locationPicker') {
    console.log('location', loc);
    editDialog.value?.setOneFormState('address', loc.poiaddress);
    location.value = {
      latitude: String(loc.latlng.lat),
      longtitude: String(loc.latlng.lng),
    };
    mapDialogVisible.value = false;
  }
};


onMounted(() => {
  window.addEventListener('message', onChooseLocation, false);
});

onUnmounted(() => {
  window.removeEventListener('message', onChooseLocation);
});

const handleAuth = () => {
  if (!currentStoreDetail.value) {
    ElMessage.error('当前未选择门店');
    return;
  }
  // todo，独立部署时，需要修改app_key
  window.open(`https://e.dianping.com/dz-open/merchant/auth?app_key=8d3d856393fd00b1&state=${
    currentStoreDetail.value.id}&redirect_url=${getCurrentUrl()}`, '_blank');
};

const handleAuthDouyin = () => {
  if (!currentStoreDetail.value) {
    ElMessage.error('当前未选择门店');
    return;
  }

  // todo，独立部署时，需要修改client_key
  const params = `charset=utf-8&client_key=awmaqf2ww1c6ry6h&out_shop_id=${
    currentStoreDetail.value.id
  }&permission_keys=1,10,12,15,16&solution_key=4&timestamp=${
    Math.floor(new Date().getTime() / 1000)
  }`;

  console.log(params);

  const sign = sha256(`3acb2da0ce6540134c1179d57f493dbe&${params}`);

  window.open(`https://auth.dylk.com/auth-isv/?${params}&sign=${sign}`, '_blank');
};

</script>
<style lang="scss" scoped>
label {
  display: inline-flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex: 0 0 auto;
  font-size: var(--el-form-label-font-size);
  color: var(--el-text-color-regular);
  height: 32px;
  line-height: 32px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}

.store-detail {

  &-top {
    display: flex;
    justify-content: space-between;
  }

  &-body {
    display: flex;
    margin: 16px 0;
    gap: 16px;

    &-info {
      display: flex;
      flex-direction: column;
      height: 140px;
      padding: 8px;
      justify-content: space-between;
      font-size: 14px;
      span {


      }
    }
  }


}
</style>
