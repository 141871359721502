<template>
  <div>
    <StoreDetail/>
    <vip />
    <div class="juou-card" style="margin-top: 16px;">

      <JuouProTable
        ref="tableRef"
        title="会员列表"
        :paginations="{ pageSize: 10 }"
        :columns="columns"
        :get-data="getData"
        :loading="loading"
        :is-dangder-delete="true"
        :format-dangder-delete-info="formatDeleteInfo"
        row-key="id"
        :operates="operates"
        :add-init-values="addInitValue"
        :codition-init-values="conditionsInitValue"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  useBaseAsync,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { ref, watchEffect } from 'vue';
import dayjs from 'dayjs';
import StoreDetail from '@/components/StoreDetail.vue';
import { useStore } from '@/store/store';
import vip from './vip.vue';

const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();
const store = useStore();


const addInitValue = {

};
const conditionsInitValue = {
};


// eslint-disable-next-line max-lines-per-function
const init = useBaseAsync(() => {
  columns.value = [
    {
      name: 'phone',
      label: '手机号',
      type: 'numberStr',
      isCondition: true,
      rule: [{ required: true }],
    },

    {
      name: 'price',
      label: '充值余额',
      type: 'string',
      hideCreate: true,
      hideEdit: true,
    },
    {
      name: 'freePrice',
      label: '赠送余额',
      type: 'string',
      hideCreate: true,
      hideEdit: true,
    },
    {
      name: 'level',
      label: '会员等级',
      type: 'number',
      hideCreate: true,
      hideEdit: true,
      isCondition: true,
      render: ({ row: { level } }: any) => `V${level}`,

    },
    {
      name: 'score',
      label: '积分（总积分）',
      type: 'number',
      hideCreate: true,
      hideEdit: true,
      render: ({ row: { score, allScore } }: any) => `${score || 0}(${allScore || 0})`,
    },
    {
      name: 'openCount',
      label: '开门次数',
      type: 'number',
      hideCreate: true,
      hideEdit: true,
    },
    {
      name: 'payPrice',
      label: '充值总金额',
      type: 'number',
      hideCreate: true,
      hideEdit: true,
    },
    {
      name: 'hourPrice',
      label: '小时券总金额',
      type: 'number',
      hideCreate: true,
      hideEdit: true,
    },
    {
      name: 'hourCount',
      label: '兑换总次数',
      type: 'number',
      hideCreate: true,
      hideEdit: true,
    },
    {
      name: 'meituanCount',
      label: '美团兑换总次数',
      type: 'number',
      hideCreate: true,
      hideEdit: true,
    },
    {
      name: 'createTime',
      label: '入会时间',
      type: 'string',
      width: 80,
      hideCreate: true,
      hideEdit: true,
      render: ({ row: { time } }: any) => dayjs(time).format('YYYY-MM-DD HH:mm:ss'),
    },

  ];
});


const operates: any[] = [];

const loading = ref(false);


const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  if (!store.currentStoreId) {
    return {
      total: 0,
      data: [],
    };
  }
  try {
    loading.value = true;
    const res = await useApi().storeCustomerApi.pageStoreCustomerStoreCustomer({
      conditions: Object.entries(filter.conditions).reduce((conditions, [key, val]) => {
        if (val === null || val === undefined || val === '') {
          return conditions;
        }
        conditions[key] = val;
        return conditions;
      }, {
        storeId: store.currentStoreId,
      } as any),
      pageQuery: {
        page: filter.paginations.page - 1,
        pageSize: filter.paginations.pageSize,
      },


    });
    const tableData: JuouProTableData = {
      total: res.data.data?.total || 0,
      data: res.data.data?.data || [],
    };

    return tableData;
  } finally {
    loading.value = false;
  }
});

const formatDeleteInfo = (row: any) => `您正在进行删除(${row.phone})操作，请谨慎操作`;


init();

watchEffect(() => {
  tableRef.value?.refresh();
});

</script>
<style lang="scss" scoped>
</style>
