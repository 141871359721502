import dayjs, { Dayjs } from 'dayjs';

/**
 * 获取当天的秒数
 * @param date 日期
 * @returns
 */
export function getTodaySec(date: Dayjs) {
  return date.second() + (date.minute() * 60) + (date.hour() * 3600);
}

/**
 * 当天秒数转换成dayjs
 * @param secs 当天的秒数
 * @returns
 */
export function todaySecToDayjs(secs: number) {
  const sec = secs % 60;
  const hour = Math.floor(secs / 3600);
  const min = Math.floor(secs % 3600 / 60);
  const res = dayjs();
  return res.hour(hour).minute(min)
    .second(sec);
}


export function to2digitNum(num: number) {
  return num < 10 ? `0${num.toFixed(0)}` : num.toFixed(0);
}


/**
 * 当天秒数格式化
 * @param secs 当天的秒数
 * @returns
 */
export function todaySecFormat(secs: number) {
  const sec = secs % 60;
  const hour = Math.floor(secs / 3600);
  const min = Math.floor(secs % 3600 / 60);
  return `${to2digitNum(hour)}:${to2digitNum(min)}:${to2digitNum(sec)}`;
}


/**
 * 格式化dayjs
 * @param date dayjs
 * @param isTime 是否为时间
 * @returns
 */
export function formatDayjs(date: Dayjs, isTime?: boolean) {
  return date.format(isTime ? 'HH:mm:ss' : 'YYYY-MM-DD HH:mm:ss');
}

export const getCurrentUrl = () => {
  const { protocol, hostname, pathname } = window.location;
  return encodeURI(`${protocol}//${hostname}${pathname}`);
};
