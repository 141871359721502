<template>
  <el-dialog width="600" title="联动设备" v-model="visible" draggable>
    <JuouProTable
      v-if="visible"
      ref="tableRef"
      :columns="columns"
      :get-data="getData"
      :on-add="addFun"
      :on-delete="deleteFun"
      :loading="loading"
      :is-dangder-delete="true"
      :format-dangder-delete-info="formatDeleteInfo"
      row-key="id"
      :operates="operates"
      :add-init-values="addInitValue"
      :codition-init-values="conditionsInitValue"
      :operation-width="100"
      >

    </JuouProTable>

  </el-dialog>

</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  JuouProForm,
  JuouProFormItem,
  useBaseAsync,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { ref, watchEffect } from 'vue';

const storeDeviceId = ref<number>();
const visible = ref(false);

const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();
const addInitValue = {

};
const conditionsInitValue = {
};


const init = useBaseAsync(() => {
  columns.value = [
    {
      name: 'deviceIdHex',
      label: '设备序列号',
      type: 'string',
      rule: [{ required: true }],
    },

  ];
});


const operates: any[] = [];

const loading = ref(false);


const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  if (!storeDeviceId.value) {
    return {
      total: 0,
      data: [],
    };
  }
  try {
    loading.value = true;
    const res = await useApi().storeDeviceApi.listStoreDeviceCollDeviceStoreDevice(storeDeviceId.value);
    const tableData: JuouProTableData = {
      total: res.data.data?.length || 0,
      data: res.data.data?.map((item: any) => ({
        deviceIdHex: item,
      })) || [],
    };
    return tableData;
  } finally {
    loading.value = false;
  }
});

const formatDeleteInfo = (row: any) => `您正在进行删除(${row.deviceIdHex})操作，请谨慎操作`;


const addFun = useBaseAsync(async(data: any) => {
  if (!storeDeviceId.value) {
    ElMessage.error('请选择一个门店设备');
    return;
  }
  await useApi().storeDeviceApi.addStoreDeviceCollDeviceStoreDevice({
    storeDeviceId: storeDeviceId.value,
    deviceIdHex: data.deviceIdHex,
  });
  ElMessage.success('添加成功');
});

const deleteFun = useBaseAsync(async(data: any) => {
  if (!storeDeviceId.value) {
    ElMessage.error('请选择一个门店设备');
    return;
  }
  await useApi().storeDeviceApi.delStoreDeviceCollDeviceStoreDevice(storeDeviceId.value, data.deviceIdHex);
  ElMessage.success('删除成功');
});


init();


defineExpose({
  show: (_storeDeviceId: number) => {
    storeDeviceId.value = _storeDeviceId;
    visible.value = true;
  },
});

</script>
<style lang="scss" scoped>
.ops {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  > * {
    display: flex;
    align-items: center;
    gap: 8px;
  }

}
</style>
