<template>
  <div class="juou-card" style="margin-top: 16px;">

    <JuouProTable
      ref="tableRef"
      title="会员规则"
      :columns="columns"
      :get-data="getData"
      :loading="loading"
      :on-add="addFun"
      add-text="添加一级"
      :on-delete="deleteFun"
      :is-dangder-delete="true"
      :format-dangder-delete-info="formatDeleteInfo"
      row-key="id"
      :operates="operates"
      :add-init-values="addInitValue"
      :codition-init-values="conditionsInitValue"
    />
  </div>
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  useBaseAsync,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { ref, watchEffect } from 'vue';
import dayjs from 'dayjs';
import StoreDetail from '@/components/StoreDetail.vue';
import { useStore } from '@/store/store';

const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();
const store = useStore();


const addInitValue = {

};
const conditionsInitValue = {
};


// eslint-disable-next-line max-lines-per-function
const init = useBaseAsync(() => {
  columns.value = [
    {
      name: 'level',
      label: '会员等级',
      type: 'numberStr',
      hideEdit: true,
      hideCreate: true,
      render: ({ $index }: any) => `V${$index}`,
    },
    {
      name: 'score',
      label: '积分',
      type: 'number',
      rule: [{ required: true }],
    },
    {
      name: 'discount',
      label: '折扣',
      type: 'string',
      rule: [{ required: true }],
    },
    {
      name: 'other',
      label: '附加福利',
      type: 'string',
    },

  ];
});


const operates: any[] = [];

const loading = ref(false);

const currentStore = ref<any>();


const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  if (!store.currentStoreId) {
    return {
      total: 0,
      data: [],
    };
  }
  try {
    loading.value = true;
    const res = await useApi().storeApi.getAdminStoreStore(store.currentStoreId);
    const tableData: JuouProTableData = {
      total: res.data.data?.vips?.length || 0,
      data: res.data.data?.vips?.map((item: any, inx: number) => {
        const cloneItem = { ...item };
        console.log(inx, res.data.data.vips.length - 1);
        if (inx !== res.data.data.vips.length - 1) {
          cloneItem.hideOp = {
            'delete': true,
          };
        }
        return cloneItem;
      }) || [],
    };
    console.log(tableData);
    currentStore.value = res.data.data;

    return tableData;
  } finally {
    loading.value = false;
  }
});

const formatDeleteInfo = (row: any) => '您正在进行删除操作，请谨慎操作';


const addFun = useBaseAsync(async(data: any) => {
  if (!currentStore.value) {
    ElMessage.error('请先切换选中一个门店');
    return;
  }
  await useApi().storeApi.updateStoreStore({
    ...currentStore.value,
    vips: [...(currentStore.value.vips || []), data],
  });
  ElMessage.success('添加成功');
});

const deleteFun = useBaseAsync(async() => {
  if (!currentStore.value) {
    ElMessage.error('请先切换选中一个门店');
    return;
  }
  const vips = currentStore.value.vips || [];
  await useApi().storeApi.updateStoreStore({
    ...currentStore.value,
    vips: vips.slice(0, vips.length - 1),
  });
  ElMessage.success('删除成功');
});


init();

watchEffect(() => {
  tableRef.value?.refresh();
});

</script>
<style lang="scss" scoped>
</style>
