<template>
  <div>
    <StoreDetail/>
    <div class="juou-card" style="margin-top: 16px;">

      <JuouProTable
        ref="tableRef"
        title="充值套餐"
        :paginations="{ pageSize: 10 }"
        :columns="columns"
        :get-data="getData"
        :on-add="addFun"
        :on-edit="updateFun"
        :on-delete="deleteFun"
        :loading="loading"
        :is-dangder-delete="true"
        :format-dangder-delete-info="formatDeleteInfo"
        row-key="id"
        :operates="operates"
        :add-init-values="addInitValue"
        :codition-init-values="conditionsInitValue"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  useBaseAsync,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { ref, watchEffect } from 'vue';
import dayjs from 'dayjs';
import StoreDetail from '@/components/StoreDetail.vue';
import { useStore } from '@/store/store';

const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();
const store = useStore();
const hiddenOption = [
  {
    value: false,
    label: '显示',
  },
  {
    value: true,
    label: '隐藏',
  },
];

const addInitValue = {

};
const conditionsInitValue = {
};


// eslint-disable-next-line max-lines-per-function
const init = useBaseAsync(() => {
  columns.value = [
    {
      name: 'sequence',
      label: '序号',
      type: 'number',
      rule: [{ required: true }],
    },
    {
      name: 'name',
      label: '套餐名称',
      type: 'string',
      rule: [{ required: true }],
    },
    {
      name: 'price',
      label: '套餐金额',
      type: 'string',
      width: 80,
      rule: [{ required: true }],
    },
    {
      name: 'freePrice',
      label: '赠送金额',
      type: 'string',
      width: 80,
      rule: [{ required: true }],
    },
    {
      name: 'score',
      label: '赠送积分',
      type: 'number',
      width: 80,
      rule: [{ required: true }],
    },
    {
      name: 'endTime',
      label: '活动截止日期',
      type: 'datepick',
      pickType: 'date',
      rule: [{ required: true }],
      render: ({ row }: any) => dayjs(row.endTime).format('YYYY/MM/DD'),
    },
    {
      name: 'lmt',
      label: '限购',
      type: 'number',
      placeholder: '0为不限',
      width: 80,
      rule: [{ required: true }],
      render: ({ row }: any) => (row.lmt === 0 ? '不限' : String(row.lmt)),
    },
    {
      name: 'count',
      label: '库存/份',
      type: 'number',
      rule: [{ required: true }],
      min: 0,
    },
    {
      name: 'saleCount',
      label: '已售/份',
      type: 'number',
      hideCreate: true,
      hideEdit: true,
    },
    {
      name: 'status',
      label: '活动状态',
      type: 'string',
      hideCreate: true,
      hideEdit: true,
      render: ({ row }: any) => (dayjs(row.endTime).endOf('d')
        .isBefore(dayjs()) ? '已停止' : '进行中'),
    },
    {
      name: 'hide',
      label: '是否隐藏',
      type: 'searchSelect',
      options: hiddenOption,
      rule: [{ required: true }],
      render: ({ row: { hide } } : any) => {
        const hideInfo = hiddenOption.find((item: any) => item.value === hide);
        if (!hideInfo) {
          return '-';
        }
        return hideInfo.label;
      },
    },

  ];
});


const operates: any[] = [];

const loading = ref(false);


const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  if (!store.currentStoreId) {
    return {
      total: 0,
      data: [],
    };
  }
  try {
    loading.value = true;
    const res = await useApi().payPackageApi.pagePayPackagePayPackage({
      conditions: Object.entries(filter.conditions).reduce((conditions, [key, val]) => {
        if (val === null || val === undefined || val === '') {
          return conditions;
        }
        conditions[key] = val;
        return conditions;
      }, {
        storeId: store.currentStoreId,
      } as any),
      pageQuery: {
        page: filter.paginations.page - 1,
        pageSize: filter.paginations.pageSize,
      },


    });
    const tableData: JuouProTableData = {
      total: res.data.data?.total || 0,
      data: res.data.data?.data || [],
    };

    return tableData;
  } finally {
    loading.value = false;
  }
});

const formatDeleteInfo = (row: any) => `您正在进行删除(${row.name})操作，请谨慎操作`;


const addFun = useBaseAsync(async(data: any) => {
  if (!store.currentStoreId) {
    ElMessage.error('请先切换选中一个门店');
    return;
  }
  await useApi().payPackageApi.addPayPackagePayPackage({
    ...data,
    storeId: store.currentStoreId,
    endTime: dayjs(data.endTime).format('YYYY-MM-DD HH:mm:ss'),
  });
  ElMessage.success('添加成功');
});

const updateFun = useBaseAsync(async(data: any) => {
  console.log(data.sec);
  await useApi().payPackageApi.updatePayPackagePayPackage({
    ...data,
    endTime: dayjs(data.endTime).format('YYYY-MM-DD HH:mm:ss'),
  });
  ElMessage.success('修改成功');
});

const deleteFun = useBaseAsync(async(data: any) => {
  if (!store.currentStoreId) {
    ElMessage.error('请先切换选中一个门店');
    return;
  }
  await useApi().payPackageApi.delPayPackagePayPackage(data.id);
  ElMessage.success('删除成功');
});


init();

watchEffect(() => {
  tableRef.value?.refresh();
});

</script>
<style lang="scss" scoped>
</style>
