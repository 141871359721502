<template>
  <div class="juou-card">
    <JuouProTable
      ref="tableRef"
      title="门店管理"
      :paginations="{ pageSize: 10 }"
      :columns="columns"
      :get-data="getData"
      :on-add="addFun"
      :on-delete="deleteFun"
      :on-edit="updateFun"
      :loading="loading"
      :is-dangder-delete="true"
      :format-dangder-delete-info="formatDeleteInfo"
      row-key="id"
      :operates="operates"
      :add-init-values="addInitValue"
      :codition-init-values="conditionsInitValue"
    />
  </div>
</template>
<script lang="ts" setup>
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  useBaseAsync,
} from 'juou-managebase-ui';
import { useApi } from '@/hooks/useApi';
import { ElMessage } from 'element-plus';
import { ref } from 'vue';
import dayjs from 'dayjs';

const columns = ref<JuouProTableColumn[]>([]);
const tableRef = ref<InstanceType<typeof JuouProTable>>();


const addInitValue = {

};
const conditionsInitValue = {
};

const statusOption = [
  {
    value: 'prepare',
    label: '门店筹备中',
  },
  {
    value: 'trial',
    label: '门店试运营',
  },
  {
    value: 'run',
    label: '门店营业中',
  },
  {
    value: 'STOP',
    label: '门店停止营业',
  },
];

const init = useBaseAsync(() => {
  columns.value = [
    {
      name: 'remark',
      label: '备注',
      type: 'string',
      isCondition: true,
    },
    {
      name: 'inx',
      label: '门店编号',
      type: 'numberStr',
    },
    {
      name: 'name',
      label: '门店名称',
      type: 'string',
      isCondition: true,
      rule: [{ required: true }],
    },
    {
      name: 'status',
      label: '门店状态',
      type: 'searchSelect',
      isCondition: true,
      options: statusOption,
      rule: [{ required: true }],
    },
    {
      name: 'payMchid',
      label: '支付商户号',
      type: 'string',
      isCondition: true,
    },
    {
      name: 'province',
      label: '省份',
      type: 'string',
      isCondition: true,
      width: 80,
      rule: [{ required: true }],
    },
    {
      name: 'city',
      label: '城市',
      type: 'string',
      isCondition: true,
      width: 80,
      rule: [{ required: true }],
    },

    {
      name: 'createTime',
      label: '开店时间',
      type: 'string',
      width: 80,
      hideCreate: true,
      hideEdit: true,
      render: ({ row: { time } }: any) => dayjs(time).format('YYYY-MM-DD HH:mm:ss'),
    },

  ];
});


const operates: any[] = [];

const loading = ref(false);


const getData = useBaseAsync<JuouProTableGetDataFunction>(async(filter: JuouProTableFilter) => {
  try {
    loading.value = true;
    const res = await useApi().storeApi.pageAllDeviceStore({
      conditions: Object.entries(filter.conditions).reduce((conditions, [key, val]) => {
        if (val === null || val === undefined || val === '') {
          return conditions;
        }
        conditions[key] = val;
        return conditions;
      }, {} as any),
      pageQuery: {
        page: filter.paginations.page - 1,
        pageSize: filter.paginations.pageSize,
      },


    });
    const tableData: JuouProTableData = {
      total: res.data.data?.total || 0,
      data: res.data.data?.data || [],
    };

    return tableData;
  } finally {
    loading.value = false;
  }
});

const formatDeleteInfo = (row: any) => `您正在进行删除(${row.name})操作，请谨慎操作`;


const addFun = useBaseAsync(async(data: any) => {
  await useApi().storeApi.addStoreStore(data);
  ElMessage.success('添加成功');
});

const updateFun = useBaseAsync(async(data: any) => {
  await useApi().storeApi.updateStoreStore(data);
  ElMessage.success('修改成功');
});
const deleteFun = useBaseAsync(async(data: any) => {
  await useApi().storeApi.deleteStoreStore(data.id);
  ElMessage.success('删除成功');
});


init();

</script>
<style lang="scss" scoped>
</style>
