<template>
  <div class="bindwx">
    <h1>绑定用户</h1>
    <el-form :model="form" ref="formRef" label-width="auto" :rules="rules" style="max-width: 600px">
      <el-form-item label="手机号">
        <div class="bindwx-phone">
          <el-input  v-model="form.phone" />
          <el-button type="primary" @click="sendSms" :disabled="countDown > 0">
            {{ countDown > 0 ?
              `${countDown}秒后重新发送` : '发送验证码' }}
          </el-button>
        </div>

      </el-form-item>
      <el-form-item label="验证码">
        <el-input v-model="form.phoneCode" />
      </el-form-item>
      <el-form-item >
        <el-button type="primary" style="width: 100%;" @click="handleBind">绑定</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>

<script lang="ts" setup>
import { onMounted, reactive, ref } from 'vue';
import {
  JuouProTable,
  JuouProTableColumn,
  JuouProTableData,
  JuouProTableFilter,
  JuouProTableGetDataFunction,
  JuouDangerDialog,
  JuouProForm,
  JuouProFormItem,
  useBaseAsync,
  useRoute,
} from 'juou-managebase-ui';
import { ElForm, ElMessage } from 'element-plus';
import { useApi } from '@/hooks/useApi';

const route = useRoute();
const countDown = ref(0);
const getCurrentUrl = () => {
  const { protocol, hostname, pathname } = window.location;
  return encodeURI(`${protocol}//${hostname}${pathname}`);
};

const loginCode = ref('');
const form = ref({
  phone: '',
  phoneCode: '',
});
const formRef = ref<InstanceType<typeof ElForm>>();

const rules = reactive<any>({
  phone: [
    { required: true, message: '请输入手机号' },
    // eslint-disable-next-line prefer-named-capture-group
    { regex: /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/u, message: '手机号格式错误' },
  ],
  phoneCode: [
    { required: true, message: '请输入验证码' },
    { regex: /^\d{6}$/u, message: '请输入6位数字验证码' },
  ],
});


const countDownStart = () => {
  countDown.value = 60;
  const timer = setInterval(() => {
    countDown.value--;
    if (countDown.value === 0) {
      clearInterval(timer);
    }
  }, 1000);
};


const sendSms = async() => {
  await formRef.value?.validate();

  try {
    await useApi().userwxApi.sendCodeuserwx({
      phone: Number(form.value.phone),
    });
    countDownStart();
    ElMessage.success('发送成功');
  } catch (error) {
    ElMessage.error('发送失败，请勿频繁发送');
  }
};

const handleBind = async() => {
  await formRef.value?.validate();

  try {
    await useApi().userwxApi.bindUseruserwx({
      phone: Number(form.value.phone),
      phoneCode: form.value.phoneCode,
      loginCode: loginCode.value,
    });
    ElMessage.success('绑定成功');
  } catch (error) {
    ElMessage.error('绑定失败');
  }
};

onMounted(() => {
  const { code, state = 'login' } = route.query;

  if (!code) {
    window.location.href
      = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbdbbccd037d8d82c&redirect_uri=${
        getCurrentUrl()
      }&response_type=code&state=${state}&scope=snsapi_userinfo#wechat_redirect`;
    return;
  }

  loginCode.value = code as string;
});

</script>

<style lang="scss" scoped>
.bindwx {
  box-sizing: border-box;
  padding: 16px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    color: #006cb2;
  }

  &-phone {
    display: flex;
    gap: 16px;
  }
}

</style>
