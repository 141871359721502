import { createApp } from 'vue';
import { JuouManageBaseUI, JuouManageBaseUIApp, JuouComponentsMap } from 'juou-managebase-ui';
import 'juou-managebase-ui/lib/lib.css';
import './style/global.scss';
import { initApi } from './hooks/useApi';

const allComponents: JuouComponentsMap = {};
const viewRequireCnt = require.context('./views', true, /index\.vue$/u);
viewRequireCnt.keys().forEach((key) => {
  const keySplits = key.split('/');
  const dirname = keySplits[keySplits.length - 2];
  console.log(dirname);
  allComponents[dirname!] = () => viewRequireCnt(key).default || viewRequireCnt(key);
});

initApi('/api');

createApp(JuouManageBaseUIApp)
  .use(JuouManageBaseUI, {
    siteName: '聚好娱自助台球',
    baseApiPath: '/api',
    viewMap: allComponents,
    description: '24小时自助台球管理后台系统',
    logo: '/logo.png',
  })
  .mount('#app');
